import { PropsWithChildren, createContext, useContext } from 'react';
import useConvertFlatToTreePageHooks from './useConvertFlatToTreePageHooks';

type ConvertFlatToTreePageHooks = ReturnType<typeof useConvertFlatToTreePageHooks>;

const ConvertFlatToTreePageContext = createContext<
  ConvertFlatToTreePageHooks | undefined
>(undefined);

export function ConvertFlatToTreePageContextProvider(props: PropsWithChildren) {
  const { children } = props;
  const pageHooks = useConvertFlatToTreePageHooks();

  return (
    <ConvertFlatToTreePageContext.Provider value={pageHooks}>
      {children}
    </ConvertFlatToTreePageContext.Provider>
  );
}

export function useConvertFlatToTreePageContext() {
  const context = useContext(ConvertFlatToTreePageContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useConvertFlatToTreePageContext can only be used inside ConvertFlatToTree page',
    );
  }

  return context;
}

