import { Form } from "antd";
import { useState } from "react";

const useConvertFlatToTreePageHooks = () => {
  const [treeData, setTreeData] = useState<Array<any>>([]);

  const [convertFlatToTreeForm] = Form.useForm();

  const convertTree = (parentId: number, arrayData: any[]) => {
    const finalArray: any[] = [];
    arrayData.forEach((item) => {
      if (item.parentId === parentId) {
        finalArray.push({
          ...item,
          key: item.id,
          title: item.nameBahasa,
          children: convertTree(item.id, arrayData)
        })
      }
    })
    return finalArray;
  }

  const handleOnConvertData = async () => {
    const { inputData } = convertFlatToTreeForm.getFieldsValue();

    const parseData = async () => {
      try {
        return JSON.parse(inputData);
      }

      catch (error) {
        return 'Invalid data type';
      }
    }

    const finalData = await parseData();

    if (Array.isArray(finalData)) {
      setTreeData(convertTree(-1, finalData));
    } else {
      alert('Invalid input data type');
    }
  }

  return {
    convertFlatToTreeForm,
    treeData,
    handleOnConvertData
  }
}

export default useConvertFlatToTreePageHooks;