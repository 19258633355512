import { Form } from "antd";

const useLoginPageHooks = () => {
  const userName = 'Test Aja';
  const [loginForm] = Form.useForm();

  const handleOnSubmitLogin = () => {
    alert('OK SUBMIT');
  }

  return {
    userName,
    loginForm,
    handleOnSubmitLogin
  }
}

export default useLoginPageHooks;