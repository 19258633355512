import { Button, Card, Form } from "antd"
import Input from "antd/es/input/Input";
import Typography from "antd/es/typography/Typography";
import { useLoginPageContext } from "src/components/page/login/useLoginPageContext";

const LoginPageContainer = () => {
  const {
    userName,
    loginForm,
    handleOnSubmitLogin
  } = useLoginPageContext();

  return (
    <Card>
      <Typography>Welcome - Login Page</Typography>
      <Form
        form={loginForm}
        name="loginForm"
        onFinish={handleOnSubmitLogin}
      >
        <label>{userName}</label>
        <Input />
        <Button
          type="primary"
          onClick={loginForm.submit}
        >
          Login
        </Button>
      </Form>
    </Card>
  )
}

export default LoginPageContainer;