import { PropsWithChildren, createContext, useContext } from 'react';
import useLoginPageHooks from './useLoginPageHooks';

type LoginPageHooks = ReturnType<typeof useLoginPageHooks>;

const LoginPageContext = createContext<
  LoginPageHooks | undefined
>(undefined);

export function LoginPageContextProvider(props: PropsWithChildren) {
  const { children } = props;
  const pageHooks = useLoginPageHooks();

  return (
    <LoginPageContext.Provider value={pageHooks}>
      {children}
    </LoginPageContext.Provider>
  );
}

export function useLoginPageContext() {
  const context = useContext(LoginPageContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useLoginPageContext can only be used inside Login page',
    );
  }

  return context;
}

