import { Button, Card, Form } from "antd"
import TextArea from "antd/es/input/TextArea";
import Tree from "antd/es/tree/Tree";
import Typography from "antd/es/typography/Typography";
import { useConvertFlatToTreePageContext } from "src/components/page/dashboard/convert-flat-to-tree/useConvertFlatToTreePageContext";

const ConvertFlatToTreePageContainer = () => {
  const {
    convertFlatToTreeForm,
    treeData,
    handleOnConvertData
  } = useConvertFlatToTreePageContext();

  return (
    <Card>
      <Typography>Convert Flat To Tree</Typography>
      <Form
        form={convertFlatToTreeForm}
        name="convertFlatToTreeForm"
        onFinish={handleOnConvertData}
      >
        <Form.Item name={'inputData'}>
          <TextArea rows={5} />
        </Form.Item>
        <Button
          type="primary"
          onClick={convertFlatToTreeForm.submit}
        >
          Convert Data
        </Button>
      </Form>
      <Tree treeData={treeData} />
    </Card>
  )
}

export default ConvertFlatToTreePageContainer;