import ConvertFlatToTreePageContainer from "src/page/convert-flat-to-tree/ConvertFlatToTreePageContainer";
import { ConvertFlatToTreePageContextProvider } from "./useConvertFlatToTreePageContext";

export default function ConvertFlatToTreePageComponent(){
  return (
    <ConvertFlatToTreePageContextProvider>
      <ConvertFlatToTreePageContainer />
    </ConvertFlatToTreePageContextProvider>
  );
}
