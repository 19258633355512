import LoginPageContainer from "src/page/login/LoginPageContainer";
import { LoginPageContextProvider } from "./useLoginPageContext";

export default function LoginPageComponent(){
  return (
    <LoginPageContextProvider>
      <LoginPageContainer />
    </LoginPageContextProvider>
  );
}
