import LoginPageComponent from "./components/page/login/LoginPageComponent";
import ConvertFlatToTreePageComponent from "./components/page/dashboard/convert-flat-to-tree/ConvertFlatToTreePageComponent";

function App() {
  return (
    <>
      <LoginPageComponent />
      <ConvertFlatToTreePageComponent />
    </>
  );
}

export default App;
